<template>
  <div class="responsive-bg-profile">
    <div class="bg-image"></div>
  </div>
  <div class="section-girls-profile" v-if="currentUser.name">
    <div class="profile-card">
      <div class="profile-card-wrap">
        <div class="profile-card-header">
          <router-link to="/" class="base-text">
            <span class="icon-arrow rotate-180"></span>
            Back
          </router-link>
          <a href="/" @click.prevent="setSignUpModal" class="green-text"
            >See all girls</a
          >
        </div>

        <div class="profile-card-body">
          <div class="left">
            <div class="name">
              {{ currentUser.name ? currentUser.name : "" }}
              <AppLabelOnline />
            </div>
            <div class="avatar" @click="setSignUpModal">
              <img
                :src="`//loveinchat.com${currentUser.photo}`"
                :alt="currentUser.name ? currentUser.name : ''"
              />
            </div>
            <!-- <div class="photo-gallery">
              <div
                class="photo-mini"
                @click="setSignUpModal"
                v-for="(photo, index) in currentUserPhotos"
                :key="index"
              >
                <img
                  :src="`//loveinchat.com${photo}`"
                  :alt="currentUser.name ? currentUser.name : ''"
                />
                <div
                  class="overlay-all"
                  v-if="index === currentUserPhotos.length - 1"
                >
                  See all photos
                </div>
              </div>
            </div> -->

            <div class="profile-about" v-if="currentUser.about_en">
              <div class="about-title green-text">About me</div>
              <AppReadMore :text="currentUser.about_en || ''" />
            </div>
          </div>
          <div class="right">
            <div class="name">
              {{ currentUser.name ? currentUser.name : "" }}
              <AppLabelOnline />
            </div>
            <div class="profile-info">
              <div class="profile-info-cell">
                <div class="label">Age:</div>
                <div class="value">{{ currentUser.age }}</div>
              </div>
              <div class="profile-info-cell">
                <div class="label">Country:</div>
                <div class="value">{{ currentUser.country }}</div>
              </div>
              <div class="profile-info-cell">
                <div class="label">Eyes color:</div>
                <div class="value">{{ currentUser.eyes_color }}</div>
              </div>
              <div class="profile-info-cell">
                <div class="label">Height:</div>
                <div class="value">{{ currentUser.height }}</div>
              </div>
              <div class="profile-info-cell">
                <div class="label">Martial status:</div>
                <div class="value">{{ currentUser.marital_status }}</div>
              </div>
              <div class="profile-info-cell">
                <div class="label">Physique:</div>
                <div class="value">{{ currentUser.physique }}</div>
              </div>
              <div class="profile-info-cell">
                <div class="label">Drinker:</div>
                <div class="value">{{ currentUser.drinker }}</div>
              </div>
            </div>
            <div class="profile-card-btns">
              <AppButton class="btn-orange" @click="setSignUpModal"
                ><span class="icon icon-eye"></span>Give a wink
              </AppButton>
              <AppButton class="btn-orange" @click="setSignUpModal"
                ><span class="icon icon-heart-fill"></span>Like
              </AppButton>
              <AppButton class="btn-orange" @click="setSignUpModal"
                ><span class="icon icon-star"></span>Add to favorites
              </AppButton>
              <AppButton class="btn-orange" @click="setSignUpModal"
                ><span class="icon icon-gift-fill"></span>Send a gift
              </AppButton>
              <AppButton class="btn-yellow" @click="setSignUpModal"
                ><span class="icon icon-email-fill"></span>Send Message
              </AppButton>
            </div>
          </div>
        </div>

        <div class="profile-card-footer"></div>
      </div>
      <div class="slider-container">
        <AppButton class="btn-prev">
          <span class="icon-arrow rotate-180"></span>
        </AppButton>
        <AppButton class="btn-next">
          <span class="icon-arrow"></span>
        </AppButton>
        <swiper
          ref="mySwiper"
          :slides-per-view="5"
          :slidesPerGroup="5"
          loop
          :space-between="16"
          :navigation="{
            nextEl: '.btn-next',
            prevEl: '.btn-prev'
          }"
        >
          <swiper-slide v-for="recUser in recommendedUsers" :key="recUser.id">
            <div
              class="slide cursor-pointer"
              @click="
                $router.push({ name: 'ProfileId', params: { id: recUser.id } })
              "
            >
              <div class="slider-image">
                <img
                  :src="`//loveinchat.com${recUser.photo}`"
                  :alt="recUser.name"
                />
              </div>
              <div class="slide-name">
                {{ recUser.name }}
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="recommended-girls-container">
        <div class="title">
          More single girls
        </div>
        <div class="d-flex flex-wrap jc-sb">
          <div
            v-for="recUser in recommendedUsers"
            :key="recUser.id"
            class="slide cursor-pointer"
            @click="
              $router.push({ name: 'ProfileId', params: { id: recUser.id } })
            "
          >
            <div class="slider-image">
              <img
                :src="`//loveinchat.com${recUser.photo}`"
                :alt="recUser.name"
              />
            </div>
            <div class="slide-name">
              {{ recUser.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import signUpModalMixin from "@/signUpModalMixin";

import AppButton from "@/components/AppButton";

import AppLabelOnline from "@/components/AppLabelOnline";

import AppReadMore from "@/components/AppReadMore";

import { Swiper, SwiperSlide } from "swiper/vue";

import SwiperCore, { Navigation } from "swiper";

import _ from "lodash";

import "swiper/swiper.scss";

SwiperCore.use([Navigation]);

export default {
  name: "ProfileId",
  components: { AppReadMore, AppLabelOnline, AppButton, Swiper, SwiperSlide },
  mixins: [signUpModalMixin],
  data() {
    return {
      currentUser: {
        name: "",
        photos: {}
      }
    };
  },
  computed: {
    ...mapGetters({
      allUsers: "allUsers"
    }),

    recommendedUsers() {
      const allUserWithOutCurrent = this.allUsers.filter(
        user => +user.id !== +this.$route.params.id
      );
      return _.shuffle(allUserWithOutCurrent).slice(0, 10);
    },
    currentUserPhotos() {
      const photos = Object.values(this.currentUser.photos);
      for (let i = photos.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [photos[i], photos[j]] = [photos[j], photos[i]];
      }
      return photos.slice(0, 4);
    }
  },
  methods: {
    ...mapActions({
      fetchUsers: "fetchUsers"
    })
  },
  async mounted() {
    await this.fetchUsers();

    const user = this.allUsers.find(
      user => +user.id === +this.$route.params.id
    );

    if (!user) {
      return this.$router.replace({ name: "NotFound" });
    }
    this.currentUser = user;
  }
};
</script>
