<template>
  <div>
    <p :class="paragraphClass">
      {{ formattedString }}
      <template v-if="text.length > maxChars">
        <div v-show="!isReadMore" :class="linkClass" @click="setSignUpModal">
          {{ moreStr }}
        </div>
        <div
          v-show="isReadMore"
          :class="linkClass"
          @click="triggerReadMore($event, false)"
        >
          {{ lessStr }}
        </div>
      </template>
    </p>
  </div>
</template>

<script>
import signUpModalMixin from "@/signUpModalMixin";

export default {
  props: {
    moreStr: {
      type: String,
      default: "Read more"
    },
    paragraphClass: {
      type: String,
      default: ""
    },
    linkClass: {
      type: String,
      default: "blue-text cursor-pointer"
    },
    lessStr: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      required: true
    },
    maxChars: {
      type: Number,
      default: 180
    },
    lineEnding: {
      type: String,
      default: "..."
    }
  },
  data() {
    return {
      isReadMore: false
    };
  },
  mixins: [signUpModalMixin],
  computed: {
    formattedString() {
      let val_container = this.text;
      if (!this.isReadMore && this.text.length > this.maxChars) {
        val_container =
          val_container.substring(0, this.maxChars) + this.lineEnding;
      }
      return val_container;
    }
  },
  methods: {
    triggerReadMore(e, b) {
      if (this.lessStr !== null || this.lessStr !== "") {
        this.isReadMore = b;
      }
    }
  }
};
</script>
